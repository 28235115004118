<i18n>
{
  "de": {
    "searchFieldPlaceholder": "Suche nach Adresse, Ort, Bezeichnung oder Nummer",
    "searchButton": "Suchen"
  }
}
</i18n>

<template>
  <form class="c-portfolio-search">
    <div class="search-input-group">
      <input
        id="portfolio-search-field"
        v-model="model"
        type="text"
        :placeholder="$t('searchFieldPlaceholder')"
        @focus="onFieldFocus"
        @blur="onFieldBlur"
        @keyup.esc="onEscape"
      />
      <button v-if="model" id="search-clear-button" type="button" @click="onEscape">
        <img class="icon" src="/icons/x.svg" />
      </button>
    </div>
    <button
      id="search-submit-button"
      type="submit"
      class="button"
      :disabled="!canSubmit"
      @click.prevent="updateSearchText"
    >
      {{ $t('searchButton') }}
    </button>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      model: null,
      initialModel: null,
    }
  },

  computed: {
    ...mapGetters({
      query: 'query/get',
    }),

    canSubmit() {
      return this.model !== null && this.model !== this.initialModel
    },
  },

  watch: {
    query() {
      this.model = this.query.search || null
    },
  },

  mounted() {
    this.model = this.query.search || null
  },

  methods: {
    ...mapActions({
      setSearchText: 'query/setSearchText',
    }),

    updateSearchText() {
      // Update initial value
      this.initialModel = this.model
      // Update query search string
      this.setSearchText({
        portfolioId: this.portfolio.id,
        search: this.model,
      })
    },

    onFieldFocus(event) {
      // Store initial value to compare
      this.initialModel = event.target.value
    },

    onFieldBlur(event) {
      if (
        this.model !== this.initialModel &&
        !['search-submit-button', 'search-clear-button'].includes(event?.relatedTarget?.id)
      ) {
        // Reset to initial value if changed
        this.model = this.initialModel
      }
    },

    onEscape(event) {
      if (!this.initialModel) {
        // Only clear if there is no initial value
        event.target.value = ''
        this.model = null
      } else {
        // Clear search field and trigger search
        event.target.value = ''
        this.initialModel = null
        this.setSearchText({
          portfolioId: this.portfolio.id,
          search: '',
        })
      }
    },
  },
}
</script>

<style lang="scss">
.c-portfolio-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  & .search-input-group {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    width: 50%;
    max-width: 500px;

    & input {
      width: 100%;
      padding-right: 40px;

      &:focus {
        border: 1px solid #aaa;
      }
    }

    & #search-clear-button {
      position: absolute;
      right: 0px;
      display: flex;
      align-items: center;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
}
</style>
