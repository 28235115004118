<template>
  <div class="tag-filters">
    <StatusFilterDropdown v-if="!!portfolio.summary.total" :portfolio="portfolio" :left="true" />
    <template v-for="(tagGroup, index) in tagGroups">
      <TagGroupDropdown
        v-if="tagGroup"
        :key="index"
        :portfolio="portfolio"
        :tag-group="tagGroup"
        :left="(index + 1) % 5 !== 4"
      />
    </template>
    <ReidaFilterDropdown
      v-if="!!portfolio.summary.total && isPortfolioStartYearInPast"
      :portfolio="portfolio"
      :left="(tagGroups.length + 1) % 5 !== 4"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import TagGroupDropdown from '@/components/portfolio/filters/TagGroupDropdown.vue'
import ReidaFilterDropdown from '@/components/portfolio/filters/ReidaFilterDropdown.vue'
import StatusFilterDropdown from '@/components/portfolio/filters/StatusFilterDropdown.vue'

export default {
  components: {
    ReidaFilterDropdown,
    TagGroupDropdown,
    StatusFilterDropdown,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    tagGroups: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      query: 'query/get',
    }),

    hasActiveFilters() {
      if (this.query === undefined || !this.query.filters) {
        return false
      }
      for (const values of Object.values(this.query.filters)) {
        if (values.length) {
          return true
        }
      }
      return false
    },

    isPortfolioStartYearInPast() {
      return this.portfolio.sim_start_year < new Date().getFullYear()
    },
  },
}
</script>

<style lang="scss">
.tag-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: var(--spacing-m) calc(var(--spacing-xs) * -1) 0 calc(var(--spacing-xs) * -1);

  & .c-tag-group-dropdown {
    margin: var(--spacing-xs);
    width: calc(20% - var(--spacing-xs) * 2);
    box-sizing: border-box;
    padding: 0;
  }

  & .c-reida-dropdown {
    margin: var(--spacing-xs);
    width: calc(20% - var(--spacing-xs) * 2);
    box-sizing: border-box;
    padding: 0;
  }

  & .c-status-dropdown {
    margin: var(--spacing-xs);
    width: calc(20% - var(--spacing-xs) * 2);
    box-sizing: border-box;
    padding: 0;
  }
}
</style>
