<!--
Required: portfolio_id
Provides: portfolioBuildingTags
-->
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('portfolioBuildingTags', {
      portfolioBuildingTagsLoading: (state) => state.loading,
      portfolioBuildingTagsLoaded: (state) => state.loaded,
    }),

    ...mapGetters({
      __portfolioBuildingTags: 'portfolioBuildingTags/getPortfolioBuildingTags',
      __query: 'query/get',
    }),

    portfolioBuildingTags() {
      return this.__portfolioBuildingTags()
    },
  },

  watch: {
    portfolio_id() {
      this.__loadPortfolioBuildingTagsByPortfolioId({ portfolioId: this.portfolio_id, reset: true })
    },

    __query() {
      this.__loadPortfolioBuildingTagsByPortfolioId({ portfolioId: this.portfolio_id, reset: false })
    },
  },

  created() {
    this.__loadPortfolioBuildingTagsByPortfolioId({ portfolioId: this.portfolio_id, reset: true })
  },

  methods: {
    ...mapActions({
      __loadPortfolioBuildingTagsByPortfolioId: 'portfolioBuildingTags/loadPortfolioBuildingTagsByPortfolioId',
    }),
  },
}
</script>
